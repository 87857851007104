import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAZdG1E6vgQkIbgGyosLBF3SknecQHRxmE",
    authDomain: "web-cv-a73b8.firebaseapp.com",
    projectId: "web-cv-a73b8",
    storageBucket: "web-cv-a73b8.appspot.com",
    messagingSenderId: "870147871307",
    appId: "1:870147871307:web:fa78b739b8956f1e5a73ce",
    measurementId: "G-5DSCZ83GXD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);