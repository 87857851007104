import React from "react";
import {Link} from "react-router-dom";

function ProjectCard({ url, name, description, year }) {
    return (
        <Link to={`https://${url}`} className="project-button">
            <div className="project-button-header">
                <h3>{name}</h3>
                <p className="badge">{year}</p>
            </div>
            <div className="project-button-body">
                <p>{description}</p>
            </div>
            <div>
                <p className="link">{url} <i className="fa-solid fa-angle-right link"></i></p>
            </div>
        </Link>
    );
}

export default ProjectCard;
