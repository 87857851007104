import React from "react";
import Section from "../components/Section";
import ProjectCard from "../components/ProjectCard";
import ContactItem from "../components/ContactItem";
import {Link} from "react-router-dom";
import Footer from "../components/Footer";
import {t} from "i18next";


function HomePage() {
    return (
        <>
            <div className="section">
                <h1>Jean Piffaut</h1>
                <div className="section section-content pt-0">
                    <p>{t('description_title')}</p>
                    <p>{t('description')}</p>
                </div>
                <p><i className="fa-solid fa-location-dot"></i> Santiago, CL</p>
            </div>
            <div className="section">
                <div className="section section-content">
                    <a href={"./#projects"} className="section-ref">
                        <div><i className="fa-solid fa-folder-closed"></i></div>
                        <div><p>{t('project_title')}</p></div>
                    </a>
                    <a href={"./#now"} className="section-ref">
                        <div><i className="fa-solid fa-bolt"></i></div>
                        <div><p>{t('now_title')}</p></div>
                    </a>
                    <a href={"./#contact"} className="section-ref">
                        <div><i className="fa-solid fa-address-card"></i></div>
                        <div><p>{t('contact_title')}</p></div>
                    </a>
                </div>
            </div>
            <Section id={"projects"} name={t('project_title')}>
                <ProjectCard url={"betty.desveladev.com"} name={t('betty_title')} year={"2024"}
                             description={t('betty_description')}/>
            </Section>
            <Section id={"now"} name={t('now_title')}>
                <p className="text-muted">{t('now_last_update')}</p>
                <p>{t('now_working_in')}</p>
                <ul>
                    <li><p>{t('now_1')}</p></li>
                    <li><p>{t('now_2')}</p></li>
                    <li><p>{t('now_4')} <Link to={"https://facto.cl/"} target={"_blank"} rel="noreferrer">Facto</Link>
                    </p></li>
                    <li><p>{t('now_3')}</p></li>
                </ul>
                <p>{t('now_actual')}</p>
                <div className="project-button-header">
                    <i className="fa-solid fa-music"></i>
                    <Link target={"_blank"}
                          to={"https://open.spotify.com/intl-es/album/5RWEssac5Dun4RvxolSjlX?si=FMs3kPPAT1e5XAWfy4Qh8g"}
                          rel="noreferrer">{t('now_actual_2')}</Link>
                </div>
                <div className="project-button-header">
                    <i className="fa-solid fa-video"></i>
                    <Link target={"_blank"} to={"https://www.netflix.com/title/80124522"}
                          rel="noreferrer">{t('now_actual_3')}</Link>
                </div>
                <div className="project-button-header">
                    <i className="fa-solid fa-gamepad"></i>
                    <a target={"_blank"} href={"https://store.steampowered.com/app/553850/HELLDIVERS_2/"}
                       rel="noreferrer">{t('now_actual_4')}</a>
                </div>
            </Section>
            <Section id={"contact"} name={t('contact_title')}>
                <ContactItem url={"https://www.linkedin.com/in/jeanpiffaut/"} name={t('contact_linkedin')}
                             description={"@jeanpiffaut"}/>
                <ContactItem url={"https://github.com/JeanPiffaut"} name={t('contact_github')}
                             description={"@JeanPiffaut"}/>
            </Section>
            <Footer/>
        </>
    );
}

export default HomePage;
