import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";

function Footer() {
    return (
        <div className={"align-items-center d-flex justify-content-between w-100"}>
            <p>© 2024 - Jean Piffaut</p>
            <LanguageSwitcher/>
        </div>
    );
}

export default Footer;
