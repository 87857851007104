import React from "react";
import {Link} from "react-router-dom";

function ContactItem({ url, name, description }) {
    return (
        <div className="contact-item">
            <p>{name}</p>
            <div className="line">
                <div><br/></div>
            </div>
            <Link target={"_blank"} className="align-items-center d-flex link text-nowrap text-truncate"
               rel="noreferrer" to={url}><p className="text-nowrap text-truncate link">{description}</p><i className="ms-2 fa-solid fa-angle-right link"></i></Link>
        </div>
    );
}

export default ContactItem;
