import React from "react";
import {t} from "i18next";

function Section({ id, name, children }) {
    return (
        <div className="section" id={id}>
            <div className="w-100 pt-3">
                <h2>{name}</h2>
                <div className="section section-content">
                    {children}
                </div>
            </div>
            <div className="upper-button">
                <a href={"./#"} className="btn" aria-label={t('go_to_top')}>
                    <i className="fa-solid fa-chevron-up"></i>
                </a>
            </div>
        </div>
    );
}

export default Section;
